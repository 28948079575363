/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Tab, Button, OverlayTrigger, Popover, Form, Alert } from 'react-bootstrap';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminGroups } from '../../../actions/adminActions';
import { addBulkDevices } from '../../../services/devices';
import { addDeviceApiCall } from '../../../services/users';
import { toggleAdminSubMenu } from '../../../actions/ui';

import { parse } from 'papaparse';

export default function AddDevice() {
  const adminGroups = useSelector((state) => state.admin.groups) || [];
  if (adminGroups.length) {
    const find = adminGroups.find((obj) => obj.GroupID === '-1');
    if (!find) {
      adminGroups.unshift({ GroupID: '-1', GroupName: 'Select an Option' });
    }
  }
  const [SelectedFile, setSelectedFile] = useState(null);
  const [IMEINumber, setIMEINumber] = useState('');
  const [Group_Association, setGroupAssociation] = useState('');
  const [GeoCoords, setGeoCoords] = useState('');
  const [Lat, setLat] = useState();
  const [Long, setLong] = useState();
  const [Real_Name, setReal_Name] = useState('');
  const [loading, setLoading] = useState(false);
  const [addUserSuccess, setAddUserSuccess] = useState(false);
  const [addUserFailure, setAddUserFailure] = useState(0);
  const dispatch = useDispatch();
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);
  const [csvDevicesAdded, setCsvDevicesAdded] = useState(false);
  const [csvDevicesError, setCsvDevicesError] = useState(false);
  const [FCIUniqueSerialNumber, setFCIUniqueSerialNumber] = useState('');

  function validateForm() {
    return Group_Association && Group_Association !== 'Select an Option' && IMEINumber.length > 0;
  }
  useEffect(() => {
    dispatch(getAdminGroups());
    if (!showAdminSubMenu) {
      dispatch(toggleAdminSubMenu());
    }
  }, []);

  function validateLatLong() {
    // Latitude validation
    if (Lat) {
      if (!(parseFloat(Lat) <= 90 && parseFloat(Lat) >= -90)) {
        return 'Invalid Latitude';
      }
    }
    // Longitude validation
    if (Long) {
      if (!(Long <= 180 && Long >= -180)) {
        return 'Invalid Longitude';
      }
    }
    return '';
  }

  function handleSubmit(event) {
    setLoading(true);
    let latLongError = validateLatLong();
    if (latLongError != '') {
      event.preventDefault();
      setAddUserFailure(latLongError);
      setLoading(false);
      setTimeout(() => {
        setAddUserFailure(0);
      }, 5000);
    } else {
      const groupId = adminGroups.find((group) => group.GroupName === Group_Association).GroupID;
      const deviceObj = {
        IMEINumber,
        Group_Association: groupId,
        ...(GeoCoords.length && { GeoCoords }),
        ...(Lat && { Lat: parseFloat(Lat) }),
        ...(Long && { Long: parseFloat(Long) }),
        ...(Real_Name.length && { Real_Name }),
        ...(FCIUniqueSerialNumber.length && { FCIUniqueSerialNumber }),
      };
      event.preventDefault();
      addDeviceApiCall(deviceObj)
        .then((res) => {
          setLoading(false);
          setAddUserSuccess(true);
          setTimeout(() => {
            setAddUserSuccess(false);
          }, 5000);
        })
        .catch((error) => {
          setAddUserFailure(error.toString().includes('409') ? 409 : 500);
          setTimeout(() => {
            setAddUserFailure(0);
          }, 5000);
        });
    }
  }

  const handleFileSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    try {
      parse(SelectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          console.log('parse completed');
          console.log(results);
          try {
            await addBulkDevices({
              devices: results.data,
            });
            setCsvDevicesAdded(true);
            setCsvDevicesError(false);
            setSelectedFile(null);
          } catch (e) {
            setCsvDevicesAdded(false);
            setCsvDevicesError(true);
          } finally {
            setLoading(false);
          }
        },
      });
    } catch (e) {
      setCsvDevicesAdded(false);
      setCsvDevicesError(true);
      setLoading(false);
    }
  };
  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: 450 }}>
      <Popover.Title as="h3">CSV Format</Popover.Title>
      <Popover.Content>
        <b>IMEINumber,Real_Name,Group_Association,GeoCoords,Lat,Long</b>
        <br />
        123,testDevice,5,&quot;31.61,74.38&quot;,31.61,74.38
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="Add-User">
      <h2 className="h2 Margin mt-0 mb-3">Add Devices</h2>
      <Tabs defaultActiveKey="form" className="mb-3">
        <Tab eventKey="form" title="Device Form">
          <Form onSubmit={handleSubmit}>
            <Form.Group size="lg" controlId="user">
              <Form.Label>IMEI Number</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                value={IMEINumber}
                onChange={(e) => setIMEINumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="user">
              <Form.Label>Real Name</Form.Label>
              <Form.Control
                type="text"
                value={Real_Name}
                onChange={(e) => setReal_Name(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Group_Association</Form.Label>
              <Form.Control
                as="select"
                // value={adminUsers[0]}
                onChange={(e) => setGroupAssociation(e.target.value)}
              >
                {adminGroups.map((adminGroup) => (
                  <option key={adminGroup.GroupID}>{adminGroup.GroupName}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group size="lg" controlId="user">
              <Form.Label>GeoCoords</Form.Label>
              <Form.Control
                type="text"
                value={GeoCoords}
                onChange={(e) => setGeoCoords(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="user">
              <Form.Label>Lat</Form.Label>
              <Form.Control type="text" value={Lat} onChange={(e) => setLat(e.target.value)} />
            </Form.Group>
            <Form.Group size="lg" controlId="user">
              <Form.Label>Long</Form.Label>
              <Form.Control type="text" value={Long} onChange={(e) => setLong(e.target.value)} />
            </Form.Group>
            <Form.Group size="lg" controlId="user">
              <Form.Label>FCI Unique Serial Number</Form.Label>
              <Form.Control
                type="text"
                value={FCIUniqueSerialNumber}
                onChange={(e) => setFCIUniqueSerialNumber(e.target.value)}
              />
            </Form.Group>
            {addUserSuccess && (
              <p style={{ backgroundColor: 'lightgreen', padding: '10px' }}>Device Added</p>
            )}
            {addUserFailure ? (
              <p style={{ backgroundColor: 'red', color: '#fff', padding: '10px' }}>
                {addUserFailure === 409 ? (
                  <>IMEI Number & FCI Unique Serial Number must be unique</>
                ) : typeof addUserFailure === 'string' || addUserFailure instanceof String ? (
                  <>{addUserFailure}</>
                ) : (
                  <>Some Error Occurred</>
                )}
              </p>
            ) : (
              <></>
            )}
            <Button block size="lg" type="submit" disabled={!validateForm()}>
              {loading && 'Adding Group'}
              {!loading && 'Submit'}
            </Button>
          </Form>
        </Tab>
        <Tab eventKey="upload" title="Upload CSV File">
          <Form onSubmit={handleFileSubmit}>
            {csvDevicesAdded ? (
              <Alert variant="success" onClose={() => setCsvDevicesAdded(false)} dismissible>
                Devices added
              </Alert>
            ) : null}
            {csvDevicesError ? (
              <Alert variant="danger" onClose={() => setCsvDevicesError(false)} dismissible>
                There was an error adding the devices. Verify the CSV file and try again.
              </Alert>
            ) : null}
            <Row>
              <Col>
                <Form.Control
                  type="file"
                  id="custom-file"
                  custom
                  label={SelectedFile ? SelectedFile.name : 'Select a CSV file'}
                  accept=".csv"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                />
              </Col>
              <Col xs={2}>
                <OverlayTrigger
                  trigger={['click', 'hover', 'focus']}
                  delay={300000}
                  placement="bottom"
                  overlay={popover}
                >
                  <Button variant="info">Info</Button>
                </OverlayTrigger>
              </Col>
            </Row>

            <Button block size="lg" type="submit" className="mt-2" disabled={!SelectedFile}>
              {loading && 'Adding Devices'}
              {!loading && 'Add Devices'}
            </Button>
          </Form>
        </Tab>
      </Tabs>
    </div>
  );
}
