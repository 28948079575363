/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCriticalDataCol } from '../../actions/devices';
import MaterialTable from 'material-table';

import './style.css';
import { getCriticalDeviceDataApiCall } from '../../services/devices';
import DownloadCSVHeaderRow from '../Utils/DownloadCSVHeaderRow';

export default function ViewCriticalDeviceData() {
  const criticalDataCol = useSelector((state) => state.devices.criticalDataCol);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(undefined);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (!criticalDataCol?.length) {
      dispatch(getCriticalDataCol());
    }
  }, [criticalDataCol?.length, dispatch]);

  const onDownloadClick = useCallback(
    async (from, to) => {
      setDownloading(true);
      const res = await getCriticalDeviceDataApiCall(0, totalCount, from, to);
      setDownloading(false);
      return res.data.map((d) => {
        d['GeoCoords'] = `'${d.GeoCoords}'`;
        return d;
      });
    },
    [totalCount],
  );

  return (
    <div style={{ overflowX: 'auto' }}>
      <DownloadCSVHeaderRow
        data={onDownloadClick}
        loading={downloading}
        filename={'critical-data.csv'}
        showFilter={true}
      />
      {criticalDataCol?.length && (
        <MaterialTable
          title="Critical Data log"
          columns={criticalDataCol}
          options={{
            debounceInterval: 700,
            padding: 'dense',
            filtering: false,
            pageSize: 30,
            search: false,
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              const page = query.page;
              const limit = 30;

              getCriticalDeviceDataApiCall(page, limit).then((res) => {
                resolve({
                  data: res.data,
                  page: query.page,
                  totalCount: res.data[0]['TotalCount'],
                });
                setTotalCount(res.data[0]['TotalCount']);
              });
            })
          }
        />
      )}
    </div>
  );
}
