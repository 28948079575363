import { SET_USER } from '../constants/action-types';
import history from '../lib/history';
import { decodeJwtToken, returnUserFromLocalStorage } from '../lib/validateuser';
import { loginApiCall } from '../services/users';

export const setUserAfterLogin = () => {
  return async (dispatch) => {
    const user = returnUserFromLocalStorage();
    if (user) {
      dispatch({ type: SET_USER, payload: user });
    }
  };
};
export const loginAction = (credentials) => {
  return async (dispatch) => {
    try {
      const apiRes = await loginApiCall(credentials);
      const { accessToken } = apiRes.data;
      localStorage.setItem('token', accessToken);
      const userData = decodeJwtToken(accessToken);
      dispatch({ type: SET_USER, payload: userData });
      history.push('/');
    } catch (error) {
      // handle when auth failed.
    }
  };
};
