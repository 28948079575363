import jwt_decode from 'jwt-decode';
export const decodeJwtToken = (token) => {
  if (token) {
    const decoded = jwt_decode(token);
    return decoded;
  }
  return null;
};
export const returnUserFromLocalStorage = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = decodeJwtToken(token);
    if (decodedToken) {
      return decodedToken;
    }
  }
  return null;
};
export const isAuthenticUser = () => {
  let authenticatedUser = false;
  const token = localStorage.getItem('token');
  const decodedToken = decodeJwtToken(token);
  if (decodedToken) {
    const { exp } = decodedToken;
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    if (secondsSinceEpoch < exp) {
      authenticatedUser = true;
    }
  }
  return authenticatedUser;
};
