import { useEffect, useState, useCallback } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { getMapData } from '../../actions/devices';
import { CustomMarker } from './customMarker';
const RED_MARKER = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
const GREEN_MARKER = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
const YELLOW_MARKER = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
const GREY_MARKER = 'https://maps.google.com/mapfiles/ms/icons/grey.png';
// const BLUE_MARKET = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => {
    let averageLat;
    let averageLong;
    const averageGeolocation = (coords) => {
      if (coords.length === 1) {
        return coords[0];
      }

      let x = 0.0;
      let y = 0.0;
      let z = 0.0;

      for (let coord of coords) {
        if (coord.latitude && coord.longitude) {
          let latitude = (coord.latitude * Math.PI) / 180;
          let longitude = (coord.longitude * Math.PI) / 180;

          x += Math.cos(latitude) * Math.cos(longitude);
          y += Math.cos(latitude) * Math.sin(longitude);
          z += Math.sin(latitude);
        }
      }

      let total = coords.length;

      x = x / total;
      y = y / total;
      z = z / total;

      let centralLongitude = Math.atan2(y, x);
      let centralSquareRoot = Math.sqrt(x * x + y * y);
      let centralLatitude = Math.atan2(z, centralSquareRoot);

      return {
        latitude: (centralLatitude * 180) / Math.PI,
        longitude: (centralLongitude * 180) / Math.PI,
      };
    };

    const coordsArr = props.markersArr.map((marker) => {
      const coordinateObj = {
        latitude: marker.Lat,
        longitude: marker.Long,
      };
      return coordinateObj;
    });

    const setClusterIcon = (cluster) => {
      let clusterMarkers = cluster.getMarkers();

      // Green by default
      let iconColor = '#00e64d';
      if (clusterMarkers.some((marker) => marker.icon.toLowerCase().includes('red'))) {
        iconColor = '#fd7567';
      } else if (clusterMarkers.some((marker) => marker.icon.toLowerCase().includes('yellow'))) {
        iconColor = '#fdf569';
      } else if (clusterMarkers.some((marker) => marker.icon.toLowerCase().includes('grey'))) {
        iconColor = 'grey';
      }

      const svg = `<svg fill="${iconColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
        <circle cx="116" cy="116" opacity=".9" r="70" />
        <circle cx="116" cy="116" opacity=".3" r="90" />
        <circle cx="116" cy="116" opacity=".2" r="110"/>
        </svg>`;
      cluster.clusterIcon_.url_ = `data:image/svg+xml;base64,${btoa(svg)}`;
      cluster.clusterIcon_.textDecoration_ = 'bold';
      cluster.clusterIcon_.textSize_ = 16;
      cluster.clusterIcon_.height_ = 48;
      cluster.clusterIcon_.width_ = 48;

      cluster.clusterIcon_.anchorIcon_ = [10, 23];
      cluster.clusterIcon_.anchorText_ = [0, 0];
    };

    const ClusterInfoBody = (cluster) => {
      const markers = cluster.getMarkers();
      return renderToString(
        <>
          <p>
            <strong>{markers.length} Devices</strong>
          </p>
          <ul className="cluster-device-list">
            {markers.map((marker) => {
              return <li key={marker.getTitle()}>{marker.getTitle()}</li>;
            })}
          </ul>
        </>,
      );
    };

    if (props.markersArr.length) {
      const { latitude, longitude } = averageGeolocation(coordsArr);
      averageLat = latitude;
      averageLong = longitude;
    }
    // props.markersArr.length && averageGeolocation(props.markersArr);
    if (!props.markersArr.length) {
      return (
        <GoogleMap defaultZoom={5} defaultCenter={{ lat: 30.266666, lng: -97.73333 }}></GoogleMap>
      );
    }

    const clusterInfoWindow = new google.maps.InfoWindow();
    const [activeMap, setActiveMap] = useState();

    const handleOpenInfoWindow = useCallback((cluster) => {
      clusterInfoWindow.setContent(ClusterInfoBody(cluster));
      clusterInfoWindow.setPosition(cluster.getCenter());
      clusterInfoWindow.open(cluster.map_);
      setActiveMap(cluster.map_);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseInfoWindow = useCallback(() => {
      clusterInfoWindow.close(activeMap);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <GoogleMap
        defaultZoom={5}
        defaultCenter={{
          lat: parseFloat(averageLat),
          lng: parseFloat(averageLong),
        }}
        onZoomChanged={handleCloseInfoWindow}
      >
        <MarkerClusterer
          averageCenter
          gridSize={40}
          enableRetinaIcons
          zoomOnClick={false}
          onClusteringEnd={(clusterer) => {
            clusterer.clusters_.map((cluster) => {
              setClusterIcon(cluster);
            });
          }}
          calculator={(markers, numStyles) => {
            return {
              text: markers.length.toString(),
              index: Math.min(markers.length, numStyles),
              title: `Click to view ${markers.length.toString()} device names`,
            };
          }}
          onClick={handleOpenInfoWindow}
        >
          {props.markersArr.map((marker, key) => {
            let markerIcon = null;
            let zIndex = 1; // Default zIndex

            if (marker.showGrey) {
              markerIcon = GREY_MARKER;
            } else if (marker.markerIcon) {
              markerIcon = marker.markerIcon;
            } else {
              // Handle red marker first when two markers are in the same location
              if (marker.Ongoing_fault === 2 || marker.Ongoing_fault === 3) {
                markerIcon = YELLOW_MARKER;
              } else if (marker.Ongoing_fault === 4 || marker.Ongoing_fault === 5) {
                markerIcon = RED_MARKER;
                zIndex = 2; // Higher zIndex for red markers
              } else {
                markerIcon = GREEN_MARKER;
              }
            }

            return (
              <CustomMarker
                key={key}
                position={{ lat: Number(marker.Lat), lng: Number(marker.Long) }}
                icon={markerIcon}
                Real_Name={marker.Real_Name}
                zIndex={zIndex}
              ></CustomMarker>
            );
          })}
        </MarkerClusterer>
      </GoogleMap>
    );
  }),
);
const Dashboard = () => {
  const mapData = useSelector((state) => state.devices.mapData);
  const mapDataFetched = useSelector((state) => state.devices.mapDataFetched);

  const [localMapData, setLocalMapData] = useState([]);
  const [timeOutIds, setTimeOutIds] = useState([]);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getCriticalDataCol());
  //   dispatch(getDataLogCol());
  // }, [dispatch]);
  useEffect(() => {
    if (!mapData.length) {
      dispatch(getMapData());
    }
    return () => {
      for (let i = 0; i < timeOutIds.length; i++) {
        clearTimeout(timeOutIds[i]);
      }
    };
  }, [dispatch, mapData.length, timeOutIds]);

  useEffect(() => {
    const tempLocalData = cloneDeep(mapData);
    for (const data of tempLocalData) {
      const { showGrey } = data;
      if (!showGrey) {
        const ongoingFault = data.Ongoing_fault;
        if (ongoingFault === 2 || ongoingFault === 3 || ongoingFault === 4 || ongoingFault === 5) {
          const ledDuration = data.LEDDuration;
          const ledTimer = data.LEDTimer;
          let diffSeconds = ledDuration - ledTimer;
          if (diffSeconds <= 0) {
            data.markerIcon = GREEN_MARKER;
          } else {
            const currentUtcTime = new Date(moment.utc().format());
            const date = data.date;
            const currentUtcTimeMoment = moment(currentUtcTime);
            const dateMoment = moment(date);
            const dateDiffSeconds = currentUtcTimeMoment.diff(dateMoment) / 1000;
            diffSeconds = diffSeconds - dateDiffSeconds;
            if (diffSeconds <= 0) {
              data.markerIcon = GREEN_MARKER;
            } else {
              const timeOutId = setTimeout(() => {
                data.markerIcon = GREEN_MARKER;
                setLocalMapData(tempLocalData);
              }, Number(diffSeconds * 1000));
              const tempTimeOutIds = [...timeOutIds];
              tempTimeOutIds.push(timeOutId);
              setTimeOutIds(tempTimeOutIds);
            }
          }
        }
      }
    }
    setLocalMapData(tempLocalData);
  }, [mapData]);

  if (!localMapData.length && !mapDataFetched) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <MyMapComponent
        isMarkerShown={true}
        markersArr={localMapData}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB0RsAhEj4mYsQ6zd863IK7deR3AB-wjxw&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `800px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      <Row>
        <Col xs={12} sm={12} lg={4}>
          <div
            style={{
              background: '#fff',
              border: '3px solid #000',
              padding: '10px',
            }}
          >
            <div>
              <img src={RED_MARKER} /> Permanent Fault
            </div>
            <br />
            <div>
              <img src={YELLOW_MARKER} /> Temporary Fault
            </div>
            <br />
            <div>
              <img src={GREEN_MARKER} /> No Fault
            </div>
            <br />
            <div>
              <img src={GREY_MARKER} /> Device not Connected
            </div>
            <br />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
