/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import { Button, Row, Col } from 'react-bootstrap';
import CsvDownloader from 'react-csv-downloader';
import Modal from 'react-bootstrap/Modal';
import Datetime from 'react-datetime';
import { useState } from 'react';

export default function DownloadCSVHeaderRow({ data, headers, filename, loading, showFilter }) {
  const [show, setShow] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  return (
    <Row className="my-3">
      <Col></Col>
      {showFilter ? (
        <Col className="text-right">
          {loading ? (
            'Exporting data...'
          ) : (
            <Button
              onClick={() => {
                setShow(true);
              }}
              variant="secondary"
            >
              Download CSV
            </Button>
          )}
        </Col>
      ) : (
        <Col className="text-right">
          <CsvDownloader datas={data} disabled={loading} columns={headers} filename={filename}>
            {loading ? 'Exporting data...' : <Button variant="secondary">Download CSV</Button>}
          </CsvDownloader>
        </Col>
      )}

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <label>Date From:</label>
              <Datetime
                dateFormat={'MM/DD/YYYY'}
                timeFormat={false}
                onChange={(e) => {
                  if (e.format) {
                    setDateFrom(e.format('YYYYMMDD'));
                  }
                }}
              />
            </Col>
            <Col>
              <label>Date To:</label>
              <Datetime
                dateFormat={'MM/DD/YYYY'}
                timeFormat={false}
                onChange={(e) => {
                  if (e.format) {
                    setDateTo(e.format('YYYYMMDD'));
                  }
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
          <CsvDownloader
            datas={async () => {
              setShow(false);
              const result = await data(dateFrom, dateTo);
              return result;
            }}
            disabled={loading || !dateFrom || !dateTo}
            columns={headers}
            filename={filename}
          >
            {loading ? (
              'Exporting data...'
            ) : (
              <Button disabled={loading || !dateFrom || !dateTo} variant="primary">
                Download
              </Button>
            )}
          </CsvDownloader>
        </Modal.Footer>
      </Modal>
    </Row>
  );
}
