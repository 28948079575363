/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useEffect, useCallback, useState } from 'react';
import { convertColumnToRealName } from '../../lib/convertColumnsToRealLanguage';
import MaterialTable from 'material-table';
import { getDeviceLoadHistoryByIdApiCall } from '../../services/devices';
import DownloadCSVHeaderRow from '../Utils/DownloadCSVHeaderRow';

export default function ViewDeviceLoadHistoryById(props) {
  const [imeiNumber, setImeiNumber] = useState();
  const [columns, setColumns] = useState();
  const [totalCount, setTotalCount] = useState(undefined);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    setImeiNumber(id);
  }, [props.location.search]);

  function getLoadHistoryColumns(data) {
    const tempColumns = [];
    if (data.length) {
      const objectKeys = Object.keys(data[0]);
      objectKeys &&
        objectKeys.map((key) => {
          if (key != 'IMEINumber') {
            tempColumns.push({
              field: key,
              title: convertColumnToRealName(key),
            });
          }
        });
    }
    setColumns(tempColumns);
  }

  const onDownloadClick = useCallback(
    async (from, to) => {
      setDownloading(true);
      const res = await getDeviceLoadHistoryByIdApiCall(imeiNumber, 0, totalCount, from, to);
      setDownloading(false);
      return res.data[1];
    },
    [totalCount, imeiNumber],
  );

  return (
    <div style={{ overflowX: 'auto' }}>
      <DownloadCSVHeaderRow
        data={onDownloadClick}
        loading={downloading}
        filename={`Device # ${imeiNumber} load history.csv`}
        showFilter={true}
      />
      <MaterialTable
        title={`Device # ${imeiNumber}`}
        data={(query) =>
          new Promise((resolve, reject) => {
            const page = query.page;
            const limit = 30;

            getDeviceLoadHistoryByIdApiCall(imeiNumber, page, limit).then((res) => {
              resolve({
                data: res.data[1],
                page: query.page,
                totalCount: res.data[0],
              });
              getLoadHistoryColumns(res.data[1]);
              setTotalCount(res.data[0]);
            });
          })
        }
        columns={columns}
        options={{
          pageSize: 30,
          search: false,
          filtering: false,
        }}
      />
    </div>
  );
}
