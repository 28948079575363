import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../Layout';
import { isAuthenticUser } from '../../lib/validateuser';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authenticatedUser = isAuthenticUser();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authenticatedUser) {
          return (
            <Layout>
              <Component {...rest} {...props} />
            </Layout>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: '/auth',
              }}
            />
          );
        }
      }}
    />
  );
};
ProtectedRoute.propTypes = {
  component: PropTypes.func,
};
export default ProtectedRoute;
