const realNames = {
  Real_Name: 'Name of FCI',
  IMEINumber: 'IMEI Number',
  date: 'Timestamp',
  PacketTimestamp: 'Packet Timestamp',
  EventTimestamp: 'Event Timestamp',
  Ongoing_fault: 'Fault Status',
  LEDDuration: 'Timer Length',
  LEDTimer: 'Current Timer Status',
  RecordID: 'Record ID',
  IndexID: 'Device Index',
  Group_Association: 'Device Group',
  rssi: 'Signal Strength/RSSI',
  FCIUniqueSerialNumber: 'FCI Unique Serial Number',
  current_status: 'Current Status',
  PhaseAEvent: 'Fault on Phase A',
  PhaseBEvent: 'Fault on Phase B',
  PhaseCEvent: 'Fault on Phase C',
};
export const convertColumnToRealName = (columnName) => {
  if (realNames[columnName]) return realNames[columnName];
  else return columnName;
};
