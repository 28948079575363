import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../Layout';
import { isAuthenticUser, returnUserFromLocalStorage } from '../../lib/validateuser';

const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  const authenticatedUser = isAuthenticUser();
  const user = returnUserFromLocalStorage();
  const AccessLevel = user && user.AccessLevel;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authenticatedUser && AccessLevel === 1) {
          return (
            <Layout>
              <Component {...rest} {...props} />
            </Layout>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: '/auth',
              }}
            />
          );
        }
      }}
    />
  );
};
AdminProtectedRoute.propTypes = {
  component: PropTypes.func,
};
export default AdminProtectedRoute;
