/* eslint-disable react/prop-types */
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import './style.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { editDeviceApiCall } from '../../../services/users';
export default function EditDeviceModal({
  accessLevel,
  show,
  setShow,
  handleClose,
  device,
  handleDeviceUpdated,
}) {
  const adminGroups = useSelector((state) => state.admin.groups) || [];
  if (adminGroups.length) {
    const find = adminGroups.find((obj) => obj.GroupID === '-1');
    if (!find) {
      adminGroups.unshift({ GroupID: '-1', GroupName: 'Select an Option' });
    }
  }
  const [IMEINumber, setIMEINumber] = useState('');
  const [Group_Association, setGroupAssociation] = useState('');
  const [GeoCoords, setGeoCoords] = useState('');
  const [Lat, setLat] = useState();
  const [Real_Name, setReal_Name] = useState('');
  const [Long, setLong] = useState();
  const [loading, setLoading] = useState(false);
  const [editDeviceSuccess, setEditDeviceSuccess] = useState(false);
  const [editDeviceFailure, setEditDeviceFailure] = useState(0);
  const [FCIUniqueSerialNumber, setFCIUniqueSerialNumber] = useState('');
  function validateForm() {
    return Group_Association && Group_Association !== 'Select an Option' && IMEINumber.length > 0;
  }
  useEffect(() => {
    if (device && device.IndexID) {
      setIMEINumber(device.IMEINumber);
      if (device.Group_Association) {
        const groupName = adminGroups.find(
          (groupObj) => parseInt(groupObj.GroupID) === parseInt(device.Group_Association),
        )?.GroupName;
        setGroupAssociation(groupName);
      }
      setGeoCoords(device.GeoCoords);
      setLat(device.Lat);
      setReal_Name(device.Real_Name);
      setLong(device.Long);
      setFCIUniqueSerialNumber(device.FCIUniqueSerialNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  function validateLatLong() {
    // Latitude validation
    if (Lat) {
      if (!(parseFloat(Lat) <= 90 && parseFloat(Lat) >= -90)) {
        return 'Invalid Latitude';
      }
    }
    // Longitude validation
    if (Long) {
      if (!(Long <= 180 && Long >= -180)) {
        return 'Invalid Longitude';
      }
    }
    return '';
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    let latLongError = validateLatLong();
    if (latLongError != '') {
      setEditDeviceFailure(latLongError);
      setLoading(false);
      setTimeout(() => {
        setEditDeviceFailure(0);
      }, 5000);
    } else {
      const groupId = adminGroups.find((group) => group.GroupName === Group_Association).GroupID;
      const deviceObj = {
        IMEINumber,
        Group_Association: groupId,
        ...(GeoCoords && GeoCoords.length && { GeoCoords }),
        ...(Lat && { Lat: parseFloat(Lat) }),
        ...(Long && { Long: parseFloat(Long) }),
        Real_Name,
        ...(FCIUniqueSerialNumber && FCIUniqueSerialNumber.length && { FCIUniqueSerialNumber }),
      };

      editDeviceApiCall(deviceObj, device.IndexID)
        .then((res) => {
          setLoading(false);
          setEditDeviceSuccess(true);
          setTimeout(() => {
            setEditDeviceSuccess(false);
          }, 5000);
        })
        .catch((error) => {
          setEditDeviceFailure(error.toString().includes('409') ? 409 : 500);
          setLoading(false);
          setTimeout(() => {
            setEditDeviceFailure(0);
          }, 5000);
        });
    }
  }
  return (
    <>
      <Modal show={show} onHide={handleDeviceUpdated}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form onSubmit={handleSubmit}>
              <h2 className="h2 Center Margin">Edit Device Form</h2>

              <Form.Group size="lg" controlId="user">
                <Form.Label>IMEI Number</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={IMEINumber}
                  disabled={accessLevel !== 1}
                  onChange={(e) => setIMEINumber(e.target.value)}
                />
              </Form.Group>

              <Form.Group size="lg" controlId="user">
                <Form.Label>Real Name</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={Real_Name}
                  onChange={(e) => setReal_Name(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Group_Association</Form.Label>
                <Form.Control
                  as="select"
                  value={Group_Association}
                  disabled={accessLevel !== 1}
                  onChange={(e) => setGroupAssociation(e.target.value)}
                >
                  {adminGroups.map((adminGroup) => (
                    <option key={adminGroup.GroupID}>{adminGroup.GroupName}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group size="lg" controlId="user">
                <Form.Label>GeoCoords</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={GeoCoords}
                  onChange={(e) => setGeoCoords(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="user">
                <Form.Label>Lat</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={Lat}
                  onChange={(e) => setLat(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="user">
                <Form.Label>Long</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={Long}
                  onChange={(e) => setLong(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="user">
                <Form.Label>FCI Unique Serial Number</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={FCIUniqueSerialNumber}
                  disabled={accessLevel !== 1}
                  onChange={(e) => setFCIUniqueSerialNumber(e.target.value)}
                />
              </Form.Group>
              {editDeviceSuccess && (
                <p style={{ backgroundColor: 'lightgreen', padding: '10px' }}>Device Edited</p>
              )}
              {editDeviceFailure ? (
                <p style={{ backgroundColor: 'red', color: '#fff', padding: '10px' }}>
                  {editDeviceFailure === 409 ? (
                    <>IMEI Number & FCI Unique Serial Number must be unique</>
                  ) : typeof editDeviceFailure === 'string' ||
                    editDeviceFailure instanceof String ? (
                    <>{editDeviceFailure}</>
                  ) : (
                    <>Some Error Occurred</>
                  )}
                </p>
              ) : (
                <></>
              )}
              <Button block size="lg" type="submit" disabled={!validateForm()}>
                {loading && 'Updating Device'}
                {!loading && 'Submit'}
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeviceUpdated}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleDeviceUpdated}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
