/* eslint-disable react/prop-types */
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import moment from 'moment-timezone';
import { Multiselect } from 'multiselect-react-dropdown';
import './style.css';
import React, { useEffect, useState } from 'react';
import { editUserApiCall, getTableColumnsApiCall } from '../../../services/users';
export default function EditUserModal({ show, setShow, handleClose, user, handleUserUpdated }) {
  const [UserNameLong, setUserNameLong] = useState();
  const [UserNameShort, setUserNameShort] = useState();
  const [ContactEmail, setContactEmail] = useState();
  const [ContactPhone, setContactPhone] = useState();
  const [AccessLevel, setAccessLevel] = useState();
  const [Timezone, setTimeZone] = useState(moment.tz.guess());
  const [timezones, setTimeZones] = useState([]);
  const [toggleFilter, setToggleHoursFilter] = useState(false);
  const [hours, setHours] = useState('');
  const [Password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [editUserSuccess, setEditUserSuccess] = useState(false);
  const [editUserFailure, setEditUserFailure] = useState(0);
  const [selectedDeviceRecordsColumns, setSelectedDeviceRecordsColumns] = useState([]);
  const [selectedDeviceInformationColumns, setSelectedDeviceInformationColumns] = useState([]);
  const [deviceRecordsColumns, setDeviceRecordsColumns] = useState([]);
  const [deviceInformationColumns, setDeviceInformationColumns] = useState([]);
  function onSelectDeviceInformationTable(selectedList, selectedItem) {
    setSelectedDeviceInformationColumns(selectedList);
  }

  function onRemoveDeviceInformationTable(selectedList, removedItem) {
    setSelectedDeviceInformationColumns(selectedList);
  }
  function onSelectDeviceRecordsTable(selectedList, selectedItem) {
    setSelectedDeviceRecordsColumns(selectedList);
  }

  function onRemoveDeviceRecordsTable(selectedList, removedItem) {
    setSelectedDeviceRecordsColumns(selectedList);
  }
  function validateForm() {
    return true;
    // return email.length > 0 && password.length > 0;
  }
  useEffect(() => {
    setTimeZones(moment.tz.names());
  }, []);
  useEffect(() => {
    getTableColumnsApiCall().then((res) => {
      const { data } = res;
      setDeviceInformationColumns(data.deviceInformationColumns);
      setDeviceRecordsColumns(data.deviceRecordsColumns);
    });
  }, []);
  useEffect(() => {
    if (user && user.AccessLevel) {
      setUserNameLong(user.UserNameLong);
      setUserNameShort(user.UserNameShort);
      setContactEmail(user.ContactEmail);
      setContactPhone(user.ContactPhone);
      setToggleHoursFilter(user.toggleFilter);
      setTimeZone(user.Timezone || moment.tz.guess());
      setHours(user.hours);
      user.visibleDeviceRecordsColumns &&
        setSelectedDeviceRecordsColumns(user.visibleDeviceRecordsColumns.split(','));
      user.visibleDeviceInformationColumns &&
        setSelectedDeviceInformationColumns(user.visibleDeviceInformationColumns.split(','));
      let accessLevelValue = '';
      if (user.AccessLevel === 1) {
        accessLevelValue = 'Admin';
      } else if (user.AccessLevel === 2) {
        accessLevelValue = 'User Admin';
      } else {
        accessLevelValue = 'User';
      }
      setAccessLevel(accessLevelValue);
      // setPassword(user.Password);
    }
  }, [user]);
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const userObj = {
      ...(UserNameLong && UserNameLong.length && { UserNameLong }),
      ...(UserNameShort && UserNameShort.length && { UserNameShort }),
      ...(ContactEmail && ContactEmail.length && { ContactEmail }),
      ...(ContactPhone && ContactPhone.length && { ContactPhone }),
      ...(Password && Password.length && { Password }),
      ...(Timezone.length && { Timezone }),
    };
    if (toggleFilter) {
      userObj.toggleFilter = 1;
      userObj.hours = hours;
    } else {
      userObj.toggleFilter = 0;
    }
    let accessKey = 3;
    if (AccessLevel === 'Admin') {
      accessKey = 1;
    } else if (AccessLevel === 'User Admin') {
      accessKey = 2;
    }
    userObj.AccessLevel = accessKey;
    userObj.visibleDeviceInformationColumns = selectedDeviceInformationColumns.length
      ? selectedDeviceInformationColumns.toString()
      : '';
    userObj.visibleDeviceRecordsColumns = selectedDeviceRecordsColumns.length
      ? selectedDeviceRecordsColumns.toString()
      : '';
    editUserApiCall(userObj, user.UserID)
      .then((res) => {
        setLoading(false);
        setEditUserSuccess(true);
        setTimeout(() => {
          setEditUserSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
        setEditUserFailure(error.toString().includes('409') ? 409 : 500);
        setTimeout(() => {
          setEditUserFailure(0);
        }, 5000);
      });
  }
  return (
    <>
      <Modal show={show} onHide={handleUserUpdated}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Add-User">
            <Form onSubmit={handleSubmit}>
              <h2 className="h2 Center Margin">Edit User Form</h2>
              <Form.Group size="lg" controlId="user">
                <Form.Label>UserNameLong</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={UserNameLong}
                  onChange={(e) => setUserNameLong(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="user">
                <Form.Label>UserNameShort</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={UserNameShort}
                  onChange={(e) => setUserNameShort(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="email">
                <Form.Label>ContactEmail</Form.Label>
                <Form.Control
                  autoFocus
                  type="email"
                  value={ContactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="number"
                  value={ContactPhone}
                  onChange={(e) => setContactPhone(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>AccessLevel</Form.Label>
                <Form.Control
                  as="select"
                  value={AccessLevel}
                  onChange={(e) => setAccessLevel(e.target.value)}
                >
                  <option>Admin</option>
                  <option>User Admin</option>
                  <option>User</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Timezone</Form.Label>
                <Form.Control
                  as="select"
                  value={Timezone}
                  onChange={(e) => setTimeZone(e.target.value)}
                >
                  {timezones.map((zone) => (
                    <option key={zone}>{zone}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Label>Set Device Information Columns</Form.Label>

              <Multiselect
                options={deviceInformationColumns}
                selectedValues={selectedDeviceInformationColumns}
                onSelect={onSelectDeviceInformationTable} // Function will trigger on select event
                onRemove={onRemoveDeviceInformationTable} // Function will trigger on remove event
                isObject={false}
              />
              <Form.Label>Set Device Records Columns</Form.Label>
              <Multiselect
                options={deviceRecordsColumns}
                selectedValues={selectedDeviceRecordsColumns}
                onSelect={onSelectDeviceRecordsTable} // Function will trigger on select event
                onRemove={onRemoveDeviceRecordsTable} // Function will trigger on remove event
                isObject={false}
              />
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Enable Filter</Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch-1"
                  checked={toggleFilter}
                  onClick={() => setToggleHoursFilter(!toggleFilter)}
                />
              </Form.Group>
              {toggleFilter && (
                <Form.Group size="lg" controlId="hours">
                  <Form.Label>Hours</Form.Label>
                  <Form.Control
                    type="number"
                    value={hours}
                    onChange={(e) => setHours(e.target.value)}
                  />
                </Form.Group>
              )}
              {editUserSuccess && (
                <p style={{ backgroundColor: 'lightgreen', padding: '10px' }}>User Updated</p>
              )}
              {editUserFailure ? (
                <p style={{ backgroundColor: 'red', color: '#fff', padding: '10px' }}>
                  {editUserFailure === 409 ? <>Email must be unique</> : <>Some Error Occurred</>}
                </p>
              ) : (
                <></>
              )}
              <Button block size="lg" type="submit" disabled={!validateForm()}>
                {loading && 'Updating User'}
                {!loading && 'Submit'}
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUserUpdated}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
