import API from './axios';

export const getTimeZonesApiCall = async () => {
  return API.get('api/admin/get-timezone');
};

export const getMessageTemplateApiCall = async () => {
  return API.get('api/admin/get-message-template');
};

export const updateMessageTemplateApiCall = async (message) => {
  return API.patch('api/admin/update-message-template', { message });
};

export const loginApiCall = async (credentials) => {
  return API.post('api/auth/login', credentials);
};

export const getAdminUsersApiCall = async () => {
  return API.get('api/admin/users');
};
export const getUserContactsApiCall = async () => {
  return API.get('api/admin/user-contacts');
};
export const updateUserContactsApiCall = async (payload) => {
  return API.patch('api/admin/update-user-contacts', payload);
};
export const getAdminGroupsApiCall = async () => {
  return API.get('api/admin/groups');
};

export const getAdminDevicesApiCall = async () => {
  return API.get('api/admin/devices');
};
export const getTableColumnsApiCall = async () => {
  return API.get('api/device-records/get-table-columns');
};

export const addUserApiCall = async (user) => {
  return API.post('api/admin/user', user);
};
export const addBulkUsers = async (users) => {
  return API.post('api/admin/bulk-users', users);
};
export const editUserApiCall = async (user, UserID) => {
  return API.patch('api/admin/user', user, {
    params: {
      UserID,
    },
  });
};

export const deleteUserApiCall = async (UserID) => {
  return API.delete('api/admin/user', {
    params: {
      UserID,
    },
  });
};

export const addGroupApiCall = async (data) => {
  return API.post('api/admin/groups', data);
};
export const editGroupApiCall = async (data, GroupID) => {
  return API.put(`api/admin/groups/${GroupID}`, data);
};

export const deleteGroupApiCall = async (GroupID) => {
  return API.delete(`api/admin/groups/${GroupID}`);
};

export const addDeviceApiCall = async (device) => {
  return API.post('api/admin/device', device);
};

export const editDeviceApiCall = async (device, IndexID) => {
  return API.patch('api/admin/device', device, {
    params: {
      IndexID,
    },
  });
};
export const deleteDeviceApiCall = async (IndexID) => {
  return API.delete('api/admin/device', {
    params: {
      IndexID,
    },
  });
};
