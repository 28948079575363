import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
const Header = () => {
  const timezoneValue = useSelector((state) => state.user.user.timezoneName);
  const timezoneAbbr = useSelector((state) => state.user.user.Timezone);
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom sticky-top">
      <div className="time-zone">
        Timezone: {timezoneValue} - {timezoneAbbr}
      </div>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          <li className="nav-item active">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                localStorage.removeItem('token');
                window.location.href = '/';
              }}
            >
              <Button size="sm" variant="outline-primary">
                Logout
              </Button>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
