import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { getAdminUsers } from '../../../actions/adminActions';
import EditUserModal from './modal';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import { toggleAdminSubMenu } from '../../../actions/ui';
import { DeleteModal } from '../../Modal';
import { deleteUserApiCall } from '../../../services/users';
import DownloadCSVHeaderRow from '../../Utils/DownloadCSVHeaderRow';

export default function EditUsers() {
  const [show, setShow] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteFailure, setDeleteFailure] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentUser, setCurrentuser] = useState();
  const handleClose = () => setShow(false);
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);
  const handleUserUpdated = () => {
    setShow(false);
    dispatch(getAdminUsers());
  };
  const handleShow = (user) => {
    setCurrentuser(user);
    setShow(true);
  };
  const handleShowDelete = (user) => {
    setShowDelete(true);
    setCurrentuser(user);
  };
  const onCancelDeleteBtn = () => {
    setShowDelete(false);
    setDeleteSuccess(false);
    setDeleteFailure(false);
  };
  const onAcceptDeleteBtn = async (userId) => {
    try {
      await deleteUserApiCall(userId);
      setDeleteSuccess(true);
      dispatch(getAdminUsers());
    } catch (error) {
      setDeleteFailure(true);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminUsers());
    if (!showAdminSubMenu) {
      dispatch(toggleAdminSubMenu());
    }
  }, [dispatch, showAdminSubMenu]);
  const adminUsers = useSelector((state) => state.admin.users) || [];
  const headers = [
    'UserID',
    'UserNameLong',
    'UserNameShort',
    'ContactEmail',
    'ContactPhone',
    'AccessLevel',
  ];
  return (
    <div>
      <DownloadCSVHeaderRow data={adminUsers} headers={headers} filename={'users.csv'} />
      <Table responsive="md">
        <thead>
          <tr>
            <th>UserID</th>
            <th>UserNameLong</th>
            <th>UserNameShort</th>
            <th>ContactEmail</th>
            <th>ContactPhone</th>
            <th>AccessLevel</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {adminUsers.map((user) => (
            <tr key={user.UserID}>
              <td>{user.UserID}</td>
              <td>{user.UserNameLong}</td>
              <td>{user.UserNameShort}</td>
              <td>{user.ContactEmail}</td>
              <td>{user.ContactPhone}</td>
              <td>{user.AccessLevel}</td>
              <td>
                <Button variant="primary" onClick={() => handleShow(user)}>
                  Edit
                </Button>
              </td>
              <td>
                <Button variant="danger" onClick={() => handleShowDelete(user)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <EditUserModal
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
        user={currentUser}
        handleUserUpdated={handleUserUpdated}
      />
      <DeleteModal
        show={showDelete}
        name="User"
        deleteSuccess={deleteSuccess}
        onClickNo={onCancelDeleteBtn}
        onClickYes={onAcceptDeleteBtn}
        deleteFailure={deleteFailure}
        id={currentUser?.UserID}
      />
    </div>
  );
}
