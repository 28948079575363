/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { toast } from 'react-toastify';
import { ScaleLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';

import EditMultiSpeakProviderModal from './modal';
import { toggleAdminSubMenu } from '../../../actions/ui';
import { DeleteModal } from '../../Modal';
import {
  deleteMultispeakProviderApiCall,
  getMultispeakProvidersGroupsApiCall,
} from '../../../services/multispeak';
import './style.css';

export default function EditMultiSpeakProvider() {
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);

  const [adminGroups, setAdminGroups] = useState([]);
  const [multispeakProviders, setMultispeakProviders] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentProvider, setCurrentProvider] = useState();
  const [loading, setLoading] = useState(false);
  const [pageReady, setPageReady] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!showAdminSubMenu) {
      dispatch(toggleAdminSubMenu());
    }
  }, [dispatch]);

  useEffect(() => {
    getMultispeakProviders().then(() => {
      setPageReady(true);
    });
  }, []);

  async function getMultispeakProviders() {
    await getMultispeakProvidersGroupsApiCall().then((res) => {
      if (res.data.length > 1) {
        setAdminGroups(res.data[0]);
        setMultispeakProviders(lookupAdminGroups(res.data[1], res.data[0]));
      }
    });
  }

  function lookupAdminGroups(msProviders, groups) {
    msProviders.map((msProvider) => {
      if (msProvider.GroupAssociations) {
        let providerGroups = [];
        providerGroups = msProvider.GroupAssociations.split(',').map((num) => {
          let adminGroup = groups.find((group) => group.GroupID == num);
          return adminGroup ? adminGroup.GroupName : null;
        });
        msProvider.GroupAssociations = providerGroups;
      } else {
        msProvider.GroupAssociations = [];
      }
    });
    return msProviders;
  }

  const handleShowEditModal = (msProvider) => {
    setShowEditModal(true);
    setCurrentProvider(msProvider);
  };

  const handleCloseEditModal = () => {
    if (!loading) {
      setShowEditModal(false);
    }
  };

  const handleProviderUpdated = () => {
    if (!loading) {
      getMultispeakProviders();
      setShowEditModal(false);
    }
  };

  const handleShowDelete = (msProvider) => {
    setShowDelete(true);
    setCurrentProvider(msProvider);
  };

  const onCancelDeleteBtn = () => {
    if (!loading) {
      setShowDelete(false);
    }
  };

  const onAcceptDeleteBtn = async (ProviderID) => {
    setLoading(true);
    deleteMultispeakProviderApiCall(ProviderID)
      .then((res) => {
        if (res.data.success) {
          toast.success('MultiSpeak Provider Deleted');
        } else {
          toast.error('Error occured');
        }
        getMultispeakProviders();
        setLoading(false);
        setShowDelete(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Error occured');
        setShowDelete(false);
      });
  };

  return (
    <div>
      {pageReady ? (
        <Table responsive size="md" className="mt-4">
          <thead>
            <tr>
              <th>Name</th>
              <th>Endpoint URL</th>
              <th>Access UserName</th>
              <th>Fault Notification Method</th>
              <th>LastUsed</th>
              <th>Group Associations</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {multispeakProviders.map((msProvider) => (
              <tr key={msProvider.ProviderID}>
                <td>{msProvider.Name}</td>
                <td>{msProvider.EndpointURL}</td>
                <td>{msProvider.AccessUserName}</td>
                <td>{msProvider.FaultNotificationMethod}</td>
                <td>{msProvider.LastUsed}</td>
                <td>
                  {msProvider.GroupAssociations.map((group) => {
                    return (
                      <span key={msProvider.ProviderID + group}>
                        <Badge bg="light">{group}</Badge>
                        &nbsp;
                      </span>
                    );
                  })}
                </td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleShowEditModal(msProvider)}
                  >
                    Edit
                  </Button>
                </td>
                <td>
                  <Button variant="danger" size="sm" onClick={() => handleShowDelete(msProvider)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <ScaleLoader className="page-loader" />
      )}
      <EditMultiSpeakProviderModal
        show={showEditModal}
        loading={loading}
        setLoading={setLoading}
        setShow={setShowEditModal}
        handleClose={handleCloseEditModal}
        handleShow={handleShowEditModal}
        msProvider={currentProvider}
        handleProviderUpdated={handleProviderUpdated}
        adminGroups={adminGroups}
      />
      <DeleteModal
        show={showDelete}
        name={`${currentProvider?.Name} MultiSpeak Provider`}
        onClickNo={onCancelDeleteBtn}
        onClickYes={onAcceptDeleteBtn}
        id={currentProvider?.ProviderID}
        loading={loading}
      />
    </div>
  );
}
