import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './style.css';
import { getMessageTemplateApiCall, updateMessageTemplateApiCall } from '../../../services/users';

export default function UpdateMessageTemplate() {
  const [message, setMessage] = useState('');
  const [updateMessageSuccess, setUpdateMessageSuccess] = useState(false);
  const [updateMessageFailure, setUpdateMessageFailure] = useState(false);

  useEffect(() => {
    getMessageTemplateApiCall()
      .then((res) => {
        setMessage(res.data.Text);
      })
      .catch((err) => {
        console.log('oopsie doopsie');
      });
  }, []);
  function handleSubmit(event) {
    setUpdateMessageSuccess(false);
    setUpdateMessageFailure(false);
    event.preventDefault();
    updateMessageTemplateApiCall(message)
      .then((res) => setUpdateMessageSuccess(true))
      .catch((err) => setUpdateMessageFailure(true));
  }

  return (
    <div className="Add-User">
      <Form onSubmit={handleSubmit}>
        <h2 className="h2 Center Margin">Add User Form</h2>
        {updateMessageSuccess && <p style={{ backgroundColor: 'lightgreen' }}>Message Updated</p>}
        {updateMessageFailure && <p style={{ backgroundColor: 'red' }}>Message Update Failed</p>}
        <Form.Group size="lg" controlId="user">
          <Form.Label>Message</Form.Label>
          <Form.Control
            autoFocus
            as="textarea"
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>

        <Button block size="lg" type="submit">
          Update
        </Button>
      </Form>
    </div>
  );
}
